import { React, Component } from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router";
import { GLOBAL_DASHBOARD_LOGIN } from "../../constants";
import { Button, Img, Modal, Input } from "arv-reactcomponents";

const {
  emailRegEx,
  userEmailErrorText,
  forgotPasswordTitle,
  forgotPasswordSubtitle1,
  forgotPasswordSubtitle2,
  forgotPasswordNextBtn,
  forgotPasswordSendBtn,
  mailSentTitle,
  mailSentSubtitle,
  mailSentImg
} = GLOBAL_DASHBOARD_LOGIN;

@withRouter
@inject("LoginStore")
@observer
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: "",
      userEmailError: "",
      isEmailValid: false,
      showLoader: false,
      mailSent: false,
    };
    this.updateUserEmail = this.updateUserEmail.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
  }
  updateUserEmail = (event) => {
    const { value: userEmail } = event.target;
    this.setState({ userEmail });
    this.validateEmail(userEmail);
  };

  validateEmail = (value) => {
    const userEmailError =
      (!emailRegEx.test(value) && userEmailErrorText) || "";
    this.setState({ userEmailError, isEmailValid: userEmailError.length === 0 });
  };

  forgotPasswordClick = () => {
    const {
      userEmail = "",
      userEmailError,
    } = this.state;
    const {
      forgotPassword,
    } = this.props.LoginStore;
    const { showLoaderView } = this.props;
    this.validateEmail(userEmail);

    if (userEmail && !userEmailError) {
      showLoaderView(true);
      this.setState({
        userEmailError: "",
      });
      forgotPassword(userEmail)
        .then((response) => {
          this.setState({ mailSent: true });
          showLoaderView(false);
        })
        .catch((error) => {
          this.setState({ userEmailError: error && error.message });
          showLoaderView(false);
        });
    }
  }

  get buttonText() {
    return this.state.isEmailValid ? forgotPasswordSendBtn : forgotPasswordNextBtn;
  }

  crossClick = () => {
    this.props.history.push("/");
    this.props.LoginStore.changeView("login");
  }

  render() {
    const {
      userEmail,
      userEmailError,
      mailSent,
    } = this.state;
    return (
      <Modal
        isOpen={true}
        onClose={this.crossClick}
        className="login-overlay"
      >
        {mailSent ? (<div>
          <div className="gd-login-title">
            {mailSentTitle}
          </div>
          <div className="gd-login-subtitle">
            {mailSentSubtitle}
          </div>
          <Img className="success-img" src={mailSentImg} alt="successImage" />
        </div>) :
          (<div>
            <div className="gd-login-title">
              {forgotPasswordTitle}
            </div>
            <div className="gd-login-subtitle">
              {forgotPasswordSubtitle2}
            </div>
            <div className="email-div">
              <Input
                type="email"
                className="input-field"
                name="user_email"
                placeholder="Email Id"
                value={userEmail}
                autoComplete="off"
                onChange={this.updateUserEmail} />
              <div className="input-field-error">{userEmailError}</div>
            </div>
            <Button
              className="overlay-button"
              onClick={this.forgotPasswordClick}
            >
              {this.buttonText}
            </Button>
          </div>)}
      </Modal >
    );
  }
}

export default ForgotPassword;
