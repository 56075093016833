const thorBasePath = `${process.env.REACT_APP_BACKEND_HOST}`;
const bbBasePath = `${process.env.REACT_APP_BLACKBOLT}`;
const bbAPIPath = `${bbBasePath}/users/business-user`;

const apis = {
  getDetails: `${thorBasePath}/api/dashboard/getDetails`,
  login: `${bbAPIPath}/token`,
  updateAccessToken: `${bbAPIPath}/token`,
  logout: `${bbAPIPath}`,
  forgotPassword: `${bbAPIPath}/v3/forgot-password`,
  passwordConfig: `${bbAPIPath}/password-config`,
  resetPassword: `${bbAPIPath}/v3`,
  resetLinkStatus: `${bbAPIPath}/reset-password`,
  setPassword: `${bbAPIPath}/activation`,
  activationLinkStatus: `${bbAPIPath}/activation`,
};

export default { ...apis };
