import { React, Component } from "react";
import { Toastr } from "arv-reactcomponents";
import loadashGet from "lodash.get";

import Header from "../../component/Header";
import DashboardBlock from "../../component/DashboardBlock";

import { toastrMessage } from "../../utils"
import { getDetails } from "../../service/index";
import { GLOBAL_DASHBOARD_TEXT } from "../../constants";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userAction: false,
      userName: "",
      userEmailId: "",
      blockData: [],
      tenantName: "",
    };
    this.userActionUpdateWindow = this.userActionUpdateWindow.bind(this);
  }
  componentDidMount() {
    this.getDashboardDetails();
  }
  userActionUpdateWindow(event) {
    const { userAction } = this.state;
    if (!event.target.matches(".userInfo-div") && userAction) {
      this.setState({ userAction: false });
    }
  }
  userActionUpdate() {
    this.setState({ userAction: !this.state.userAction });
  }
  async getDashboardDetails() {
    this.props.showLoaderView(true);
    try {
      const response = await getDetails();
      const {
        options: blockData = [],
        name: tenantName = '',
        userEmailId = '',
        userName = ''
      } = response || {};
      this.setState({
        blockData,
        tenantName,
        userEmailId,
        userName,
        showLoader: false,
      });
      this.props.showLoaderView(false);
    } catch (error) {
      const toastrErrorText = loadashGet(
        error,
        "message",
        GLOBAL_DASHBOARD_TEXT.getDetailsError
      );
      this.props.showLoaderView(false);
      if (
        toastrErrorText === GLOBAL_DASHBOARD_TEXT.updateAccessTokenError ||
        error.status === "403"
      ) {
        this.clearStateFields();
        this.props.loginStateUpdate(false);
      } else {
        Toastr.showToastr({
          className: "toastr-container toastr-error",
          message: toastrMessage("error", toastrErrorText),
          timeout: 3000,
        });
      }
    }
  }

  clearStateFields() {
    this.setState({
      userName: "",
      userEmailId: "",
      blockData: [],
      tenantName: "",
    });
  }

  render() {
    const {
      userAction,
      blockData,
      userEmailId,
      userName,
      tenantName,
    } = this.state;
    const userData = { userEmailId, userName, tenantName };
    return (
      <div
        className="gd-dashboard-view"
        onClick={this.userActionUpdateWindow}
      >
        <Header
          loginStateUpdate={this.props.loginStateUpdate.bind(this)}
          userAction={userAction}
          userActionUpdate={this.userActionUpdate.bind(this)}
          userData={userData}
        />
        <DashboardBlock blockData={blockData} />
      </div>
    );
  }
}

export default Dashboard;
