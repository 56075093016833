import { observable } from "mobx";
import LoginStoreActions from "./actions";

class LoginStoreClass extends LoginStoreActions {
  @observable currentView = "login";
  @observable emailId = "";
  @observable tenantId = "";
  @observable passwordConfig = {
    regex: "",
    errorMessage: "",
    criteria: []
  }
}

export default LoginStoreClass;
