import {
  apiEndPoints,
  blackBoltUrl,
  updateAccessToken,
  cookiesToDelete,
} from "../constants/index";
import {
  readCookie,
  setUserToken,
  getCorrelationId,
  eraseCookie,
  authHeader,
  updateAccessTokenError,
} from "../utils/index";

let fetchTokenPromise;
const triggerAPI = (url, requestBody, type) => {
  return fetch(url, requestBody)
    .then((response) => handleFetchResponse(response, requestBody))
    .then((response) => {
      const [status, res, statusCode] = response && Array.from(response) || [];
      if (!status) {
        if (statusCode === 403 || type === updateAccessToken) {
          cookiesToDelete.forEach((val) => {
            eraseCookie(val);
          });
          throw updateAccessTokenError();
        } else if (statusCode === 401 && blackBoltUrl.indexOf(type) === -1) {
          return retry(url, requestBody, type);
        } else {
          throw {
            ...res,
            statusCode,
          };
        }  
      } else {
        return res;
      }
    })
    .catch(error => {
      throw error;
    });
};

function handleFetchResponse(response, requestBody) {
  try {
    return Promise.all([
      response.ok,
      response.json().catch(
        (_) =>
          response.text().then(function (text) {
            return `${text} correlationId: ${requestBody.headers.correlationid}`;
          }),
        function (err) {
          return ` correlationId: ${requestBody.headers.correlationid}`;
        }
      ),
      response.status,
    ]);
  } catch (err) {
    return err;
  }
}

function retry(url, requestBody, type) {
  if (!fetchTokenPromise) {
    fetchTokenPromise = getTokens();
  }
  return fetchTokenPromise
    .then((accessToken) => {
      fetchTokenPromise = null;
      const { authorization } = authHeader(accessToken);
      requestBody.headers.authorization = authorization;
      return triggerAPI(url, requestBody, type);
    })
    .catch((err) => {
      fetchTokenPromise = null;
      throw err;
    });
}

const getTokens = () => {
  const refresh_token = readCookie("refreshToken");
  if (!refresh_token) {
    cookiesToDelete.forEach((val) => {
      eraseCookie(val);
    });
    throw updateAccessTokenError();
  } else {
    const body = JSON.stringify({
      grant_type: "refresh_token",
      refresh_token,
    });
    return post("updateAccessToken", {}, body)
      .then((res) => {
        const {
          access_token: accessToken,
          refresh_token: refreshToken,
        } = res.data;
        setUserToken(accessToken, refreshToken);
        return accessToken;
      })
      .catch((err) => {
        cookiesToDelete.forEach((val) => {
          eraseCookie(val);
        });
        throw err;
      });
  }
};

const get = (type, customHeaders = {}, param = null) => {
  let url = apiEndPoints[type];
  const headers = {
    ...customHeaders,
  };
  GetStaticHeaders(headers);
  const requestBody = {
    method: "GET",
    headers,
  };
  if (param) {
    url = `${url}${"/"}${param}`;
  }
  return triggerAPI(url, requestBody, type);
};

const post = (type, customHeaders, body, param = null) => {
    let url = apiEndPoints[type];
    const headers = {
      ...customHeaders,
    };
    GetStaticHeaders(headers);
    const requestBody = {
      method: "POST",
      headers,
      body,
    };
    if (param) {
      url = `${url}${"/"}${param}`;
    }
  return triggerAPI(url, requestBody, type);
};

const put = (type, customHeaders, body, param = null) => {
  let url = apiEndPoints[type];
  const headers = {
    ...customHeaders,
  };
  GetStaticHeaders(headers);
  const requestBody = {
    method: "PUT",
    headers,
    body,
  };
  if (param) {
    url = `${url}${"/"}${param}`;
  }
  return triggerAPI(url, requestBody, type);
};

const deleteApi = (type, customHeaders, param = null) => {
  let url = apiEndPoints[type];
  const headers = {
    ...customHeaders,
  };
  GetStaticHeaders(headers);
  const requestBody = {
    method: "DELETE",
    headers,
  };
  if (param) {
    url = `${url}${"/"}${param}`;
  }
  cookiesToDelete.forEach((val) => {
    eraseCookie(val);
  });  
  return triggerAPI(url, requestBody, type);
};

const GetStaticHeaders = (headers) => {
  const accessToken = readCookie("accessToken");
  const staticHeaders = {
    accept: "application/json",
    "content-type": "application/json",
  };
  if (accessToken) {
    staticHeaders.authorization = `Bearer ${accessToken}`;
  }
  Object.assign(
    headers,
    {
      correlationId: getCorrelationId(),
    },
    staticHeaders
  );
};

export { get, post, put, deleteApi };
