
//import './App.css';
import { React, Component } from 'react';
import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home";

class App extends Component {
  render() {
    return (<Switch>
      <Route exact path="/" component={() => <Home currentView="login" />} />
      <Route
        exact
        path="/reset-password"
        component={() => <Home currentView="resetPassword" />}
      />
      <Route
        exact
        path="/activation"
        component={() => <Home currentView="setPassword" />}
      />
    </Switch>)
  }
}
export default App;
