import { React, Component } from 'react';
import { Loader, Toastr, Img } from "arv-reactcomponents";
import { withRouter } from "react-router";
import { readCookie, toastrMessage } from "../../utils/index";
import { userLoggedIn } from "../../constants"

import Login from "../Login/index";
import Dashboard from "../Dashboard/index";

@withRouter
class Home extends Component {
    constructor() {
        super();
        this.state = {
            showView: false,
            isLoggedIn: false,
            showLoader: false
        };
        this.showLoaderView = this.showLoaderView.bind(this);
        this.loginStateUpdate = this.loginStateUpdate.bind(this);
    }

    componentWillMount() {
        const accessToken = readCookie('accessToken');
        const { currentView } = this.props;
        if (accessToken && currentView !== "login") {
            Toastr.showToastr({
                className: "toastr-container toastr-info",
                message: toastrMessage("info",userLoggedIn),
                timeout: 3000,
            });
            this.props.history.push("/");
        }

    }

    componentDidMount() {
        this.setPageView();
    }
    setPageView() {
        const accessToken = readCookie('accessToken');
        if (accessToken) {
            this.setState({
                showView: true,
                isLoggedIn: true
            });
        } else {
            this.setState({
                showView: true,
                isLoggedIn: false
            });
        }
    }
    static renderLoader() {
        return (
            <div className="gd-bodyoverlay">
                <div className="gd-bodyoverlay-loadercontainer">
                    <Loader />
                </div>
            </div>
        );
    }
    showLoaderView(showLoader) {
        this.setState({ showLoader });
    }

    loginStateUpdate(isLoggedIn) {
        this.setState({ isLoggedIn });
    }

    render() {
        const { currentView } = this.props;
        const { showView, showLoader, isLoggedIn } = this.state;
        return (
            showView && <div className="global-dashboard">
                {showLoader && Home.renderLoader()}
                {isLoggedIn ? <Dashboard showLoaderView={this.showLoaderView} loginStateUpdate={this.loginStateUpdate} />
                    : <Login showLoaderView={this.showLoaderView} loginStateUpdate={this.loginStateUpdate} currentView={currentView} />}
            </div>)
    }
}

export default Home;