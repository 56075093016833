import { React, Component } from "react";
import { Toastr, Img } from "arv-reactcomponents";
import { toastrMessage } from "../../utils";
import { GLOBAL_DASHBOARD_TEXT } from "../../constants";

class DashboardBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeBlock: null,
      serviceInfo: {},
    };
  }
  openService(link) {
    if (link) {
      window.open(link, "_blank");
    } else {
        Toastr.showToastr({
            className: "toastr-container toastr-error",
            message: toastrMessage("error",GLOBAL_DASHBOARD_TEXT.noLink),
            timeout: 2000,
        });
    }
  }
  render() {
    const { blockData } = this.props;
    return (
      <div className="global-dashboard-block">
        <div className="row">
          {blockData &&
            blockData
              .sort((a, b) => {
                if (a.order > b.order) return 1;
                else return -1;
              })
              .map(
                (block) =>
                  block.display && (
                    <div
                      key={block.order}
                      className="col-lg-3 col-md-4 col-sm-4 dashboard-service"
                      onClick={this.openService.bind(this, block.link)}
                    >
                      <div className="dashboard-service-block">
                        <div  className="service-info-block">
                          <div className="service-label">{block.label}</div>
                          <div className="service-description">
                            {block.description}
                          </div>
                        </div>
                        <div className="service-image">
                          <Img src={block.logo} alt={block.label} />
                        </div>  
                      </div>
                    </div>
                  )
              )}
        </div>
      </div>
    );
  }
}

export default DashboardBlock;
