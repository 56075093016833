import { action } from "mobx";
import {
  login,
  logout,
  forgotPassword,
  resetPassword,
  getPasswordConfig,
  resetLinkStatus,
  setPassword,
  activationLinkStatus,
} from "./service";
import { formatLoginQuery, setUserToken, eraseCookie, readCookie, decodeToken } from "../../utils";
import { cookiesToDelete } from "../../constants";

class LoginStoreAction {
  // Login actions goes here

  @action
  changeView(view) {
    this.currentView = view;
  }

  @action
  setEmailId = value => {
    this.emailId = value;
  };

  @action
  loginAction = (email, password) => {
    const payload = formatLoginQuery(email, password);

    return new Promise((resolve, reject) => {
      login({}, payload)
        .then(response => {
          const { access_token = null, refresh_token = null } = response && response.data;
          if (access_token && refresh_token) {
            const userData = setUserToken(access_token, refresh_token);
            resolve(userData);
          } else
            reject("Invalid token data");
        })
        .catch(error => {
          if (error && error.errors) {
            error = error.errors[0];
          }
          reject(error);
        });
    });
  };

  @action
  logoutAction = () => {
    return new Promise((resolve, reject) => {
      const accessToken = readCookie('accessToken');
      if (accessToken) {
        const accessTokenDecode = decodeToken(accessToken);
        const { user_id = null, session = null, tenantId = null } = accessTokenDecode;
        let headers = {
          "X-Tenant-ID": tenantId,
        };
        let param = `${user_id}/sessions/${session}/logout`;
        logout(headers, param)
          .then(response => resolve(true))
          .catch(error => reject(error));
      } else {
        this.postLogoutAction();
        resolve(true);
      }
    });
  };

  @action
  postLogoutAction = () => {
    cookiesToDelete.forEach(val => {
      eraseCookie(val);
    });
  }

  @action
  forgotPassword = emailId => {
    let requestBody = {
      username: emailId
    };
    return new Promise((resolve, reject) => {
      forgotPassword({}, JSON.stringify(requestBody))
        .then(response => {

          resolve(response);
        })
        .catch(error => {

          reject(error.error);
        });
    });
  };

  @action
  getPasswordConfig = tenantId => {
    let headers = {
      "X-Tenant-ID": tenantId,
    };
    return new Promise((resolve, reject) => {
      getPasswordConfig(headers)
        .then(response => {
          this.passwordConfig = response.data;
          resolve(response);
        })
        .catch(error => {

          reject(error);
        });
    });
  };


  @action
  passwordSubmit = (password, tenantId, token) => {
    let requestBody = {
      newPassword: password
    };
    let headers = {
      "X-Tenant-ID": tenantId,
    };
    if (this.currentView === "resetPassword") {
      return new Promise((resolve, reject) => {
        resetPassword(headers, JSON.stringify(requestBody), token)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error.error);
          });
      });
    }
    else {
      return new Promise((resolve, reject) => {
        setPassword(headers, JSON.stringify(requestBody), token)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error.error);
          });
      });
    }
  };

  @action
  linkStatus = token => {
    let headers = {};

    if (this.currentView === "resetPassword") {
      return new Promise((resolve, reject) => {
        resetLinkStatus(headers, token)
          .then(response => {
            this.emailId = response.data.emailId;
            this.tenantId = response.data.tenantId;

            resolve(response);
          })
          .catch(error => {
            reject(error.error);
          });
      });
    }
    else {
      return new Promise((resolve, reject) => {
        activationLinkStatus(headers, token)
          .then(response => {
            this.emailId = response.data.emailId;
            this.tenantId = response.data.tenantId;
            resolve(response);
          })
          .catch(error => {
            reject(error.error);
          });
      });
    }

  }
}
export default LoginStoreAction;
