const cdnBasePath = `https://${process.env.REACT_APP_CDNBASEPATH}`;

const GLOBAL_DASHBOARD_TEXT = {
  getDetailsError: "Error while fetching details, please try again after sometime.",
  updateAccessTokenError: "No token present",
  noLink: "No link present."
};

const GLOBAL_DASHBOARD_LOGIN = {
  loginTitle: "Login",
  loginCTAText: "Submit",
  emailRegEx: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
  userEmailErrorText: "Please enter a valid email address",
  userPasswordErrorText: "Please enter a password",
  loginErrorToastr: "Error while login",
  defaultLoginError: "Incorrect username/password",
  statusCode: ["401", "400","404"],
  forgotPasswordBtnText: "Forgot password?",
  forgotPasswordTitle: "Forgot Password",
  forgotPasswordSubtitle1: "Enter your registered Email Address",
  forgotPasswordSubtitle2: "A link will be sent to your registered email id for verification",
  forgotPasswordNextBtn: "Next",
  forgotPasswordSendBtn: "Send",
  mailSentTitle: "Email Sent",
  mailSentSubtitle: "Email sent. Please check (kindly check your spam folder as well if you can’t find the email).",
  mailSentImg: `${cdnBasePath}/dashboard/email_sent.svg`,
};

const GLOBAL_DASHBOARD_RESET_PWD = {
  resetPasswordTitle: "Reset Password",
  resetPasswordSubtitle: "Reset password for your email id ",
  resetPasswordBtn: "Submit",
  passwordMismatchError: "Passwords do not match!",
  passwordInfoCircle: `${cdnBasePath}/dashboard/info.svg`,
  greenSuccess: `${cdnBasePath}/dashboard/green_succes.svg`,
  redError: `${cdnBasePath}/dashboard/red_error.svg`,
  sucsessTitle: "Congrats!",
  successSubtitle: "You have successfully reset your password.",
  successCheckImg: `${cdnBasePath}/dashboard/success_check.png`,
  activationTitle : "Set Password",
  activationSubtitle: "Set a new password for your email id "
};

const userLoggedIn = "Hey ! You are already logged in."

const crossIcon = `${cdnBasePath}/dashboard/cancel_icon.svg`;
const eyeOpenIcon = `${cdnBasePath}/dashboard/eye_open.svg`;
const eyeClosedIcon = `${cdnBasePath}/dashboard/eye_closed.svg`;

const GLOBAL_DASHBOARD_LOGO = `${cdnBasePath}/dashboard/omuniCloud.png`;
const GLOBAL_DASHBOARD_LOGOUT = `${cdnBasePath}/dashboard/logout.png`;
const GLOBAL_DASHBOARD_PROFILE = `${cdnBasePath}/dashboard/profile.png`;

const blackBoltUrl = ["login", "logout"];
const updateAccessToken = "updateAccessToken";
const cookiesToDelete = ["accessToken", "refreshToken"];

const TOASTR_IMAGE = {
  error: `${cdnBasePath}/dashboard/toastr_error.svg`,
  info: `${cdnBasePath}/dashboard/toastr_info.svg`,
  warning: `${cdnBasePath}/dashboard/toastr_notif.svg`,
  success:`${cdnBasePath}/dashboard/toastr_success.svg`

};

export { default as apiEndPoints } from "./apiEndPoints.js";
export {
  GLOBAL_DASHBOARD_TEXT,
  blackBoltUrl,
  updateAccessToken,
  cookiesToDelete,
  GLOBAL_DASHBOARD_LOGO,
  GLOBAL_DASHBOARD_LOGOUT,
  GLOBAL_DASHBOARD_PROFILE,
  GLOBAL_DASHBOARD_LOGIN,
  GLOBAL_DASHBOARD_RESET_PWD,
  crossIcon,
  eyeClosedIcon,
  eyeOpenIcon,
  userLoggedIn,
  TOASTR_IMAGE
};
