import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "mobx-react";
import App from './App';
import { init } from "./stores";
import "./index.scss";

const stores = init(window.DATA);
ReactDOM.hydrate(
  <Provider {...stores}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root"),
);

if (module.hot) {
  console.log("hot reloading active%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%");
  module.hot.accept();
}
