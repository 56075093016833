import LoginStoreClass from "./LoginStore";

/**
  Inject Inital State into Stores
*/
const setState = (state) => {
  const stores = {};

  Object.assign(stores, {
    LoginStore: state
      ? new LoginStoreClass(state.LoginStore)
      : new LoginStoreClass(),
  });
  return stores;
};

class Stores {
  constructor(state = {}) {
    this.setStores = this.setStores.bind(this);
    this.getStores = this.getStores.bind(this);
    this.stores = setState(state);
  }
  getStores() {
    return this.stores;
  }
  setStores(state) {
    this.stores = setState(state);
  }
}
export default Stores;
