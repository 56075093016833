import {get, post, put, deleteApi} from '../../api';

const login = (headers, body) =>
post("login", headers, body);

const logout = (headers, body) =>
deleteApi("logout", headers, body);

const getPasswordConfig = headers => get("passwordConfig", headers);

const forgotPassword = (headers, body) =>
  post("forgotPassword", headers, body);

const resetPassword = (headers, body, param) =>
put("resetPassword", headers, body,  `reset-password?token=${param}`); 

const resetLinkStatus = (headers, param) =>
get("resetLinkStatus", headers, `link-status?token=${param}`); 

const setPassword = (headers, body, param) =>
put("setPassword", headers, body,  `set-password?token=${param}`); 

const activationLinkStatus = (headers, param) =>
get("activationLinkStatus", headers, `link-status?token=${param}`); 

export {
  login,
  logout,
  getPasswordConfig,
  forgotPassword,
  resetPassword,
  resetLinkStatus,
  setPassword,
  activationLinkStatus
};