import { React, Component } from "react";
import { inject, observer } from "mobx-react";
import { Img } from "arv-reactcomponents";
import LoginSection from "../../component/LoginSection/index";
import ForgotPassword from "../../component/ForgotPassword/index";

import { GLOBAL_DASHBOARD_LOGO as headerLogo } from "../../constants";
import ModifyPassword from "../../component/ModifyPassword";

@inject("LoginStore")
@observer
class Login extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { currentView } = this.props;
    this.props.LoginStore.changeView(currentView);
  }

  currentViewSelector = () => {
    let { currentView } = this.props.LoginStore;
    console.log("currentview", currentView);
    switch (currentView) {
      case "login":
        return (<LoginSection
          showLoaderView={this.props.showLoaderView.bind(this)}
          loginStateUpdate={this.props.loginStateUpdate.bind(this)}
        />);
      case "resetPassword":
      case "setPassword":
        return <ModifyPassword showLoaderView={this.props.showLoaderView.bind(this)} />;
      case "forgotPassword":
        return <ForgotPassword showLoaderView={this.props.showLoaderView.bind(this)} />;
    }
  };

  render() {

    return (
        <div className="gd-login-view gd-login-bg-img">
          <div className="gd-login-logo">
            <Img src={headerLogo} alt="header logo" />
          </div>
          {this.currentViewSelector()}
        </div>
    );
  }
}

export default Login;
