import Stores from "./Stores";

let stores;
const init = data => {
  stores = new Stores(data).getStores();
  return stores;
};
const getStores = () => stores;

export { init, getStores };

export { default as LoginStoreClass } from "./LoginStore";
