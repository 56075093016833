import { React, Component } from "react";
import { observer, inject } from "mobx-react";
import { Img } from "arv-reactcomponents";

import {
  GLOBAL_DASHBOARD_LOGO as headerLogo,
  GLOBAL_DASHBOARD_LOGOUT as logoutLogo,
  GLOBAL_DASHBOARD_PROFILE as profileLogo,
} from "../../constants";

@inject("LoginStore")
@observer
class Header extends Component {
  constructor(props){
    super(props);
    this.userActionUpdate = this.userActionUpdate.bind(this);
    this.userActionLogout = this.userActionLogout.bind(this);
  }
  userActionUpdate() {
    this.props.userActionUpdate();
  }
  userActionLogout() {
    const {
      logoutAction,
    } = this.props.LoginStore;
    logoutAction()
      .then((response) => this.props.loginStateUpdate(false))
      .catch((error) => this.props.loginStateUpdate(false));
  }
  render() {
    const { userData = {}, userAction } = this.props;
    return (
      <div className="logostyle">
        <div className="innerlogo">
          <Img src={headerLogo} alt="icon"/>
        </div>
        <div className="userInfo-div">
            <div
              className="userInfo"
              onClick={this.userActionUpdate}
            >
              <div className="userProfile-tenant ">{userData.tenantName}</div>
              <div className="userProfile-icon">
                <Img src={profileLogo} alt="Profile"/>
              </div>
            </div>
            {userAction && (
              <div className="gd-profile-details">
                <div className="userProfile-action-info">
                  <div className="userProfileName">
                    <Img
                      src={profileLogo}
                      alt="Profile"
                      className="userProfile-icon-inner"/>
                  </div>
                  <div className="userProfile-detail">
                    {userData.userName && (
                      <div className="userProfile-name">
                        {userData.userName}
                      </div>
                    )}
                    <div className="userProfile-email">
                      {userData.userEmailId}
                    </div>
                  </div>
                </div>
                <div
                  className="userProfile-action-logout"
                  onClick={this.userActionLogout}
                >
                  <div className="userProfile-action-logoutIcon">
                    <Img src={logoutLogo} alt="logout" />
                  </div>
                  <div className="userProfile-action-logoutLink">Logout</div>
                </div>
              </div>
            )}
          </div>
      </div>
    );
  }
}

export default Header;
