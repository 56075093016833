import uuid from "react-native-uuid";
import { TOASTR_IMAGE } from "../constants";
import { Img } from "arv-reactcomponents";

const createCookie = function (name, value, expireTime) {
  let expires = "";
  if (expireTime) {
    const date = new Date();
    date.setTime(expireTime);
    expires = `; expires= ${date.toGMTString()}`;
  }
  const cookieText = `${name} = ${value} ${expires}; path=/;`;
  const cookieSubText = window.location.hostname !== "localhost" ? ` SameSite=None; Secure; domain=${process.env.REACT_APP_DOMAIN_NAME}` : '';
  document.cookie = `${cookieText}${cookieSubText}`;
};

const readCookie = function (name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");
  for (let key of ca.keys()) {
    let c = ca[key];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

const eraseCookie = function (name) {
  createCookie(name, "", -1);
};

const authHeader = (token = readCookie("accessToken")) => {
  const retObj = token
    ? {
      authorization: `Bearer ${token}`,
    }
    : {};
  return retObj;
};

const getCorrelationId = () => uuid.v4();

const decodeToken = (token) => {
  let tokenString = token;
  tokenString = tokenString.split(".");
  let [, str] = tokenString;
  str = str.replace("/-/g", "+");
  str = str.replace("/_/g", "/");
  switch (str.length % 4) {
    case 0:
      break;
    case 2:
      str += "==";
      break;
    case 3:
      str += "=";
      break;
    default: {
      const err = "Invalid token";
      throw err;
    }
  }
  // eslint-disable-next-line
  str = (str + "===").slice(0, str.length + (str.length % 4));
  str = str.replace(/-/g, "+").replace(/_/g, "/");

  str = decodeURIComponent(escape(atob(str)));

  str = JSON.parse(str);
  return str;
};

const formatLoginQuery = (username, password) =>
  JSON.stringify({
    grant_type: "password",
    username,
    password,
  });

const setUserToken = (accessToken, refreshToken) => {
  const token = decodeToken(refreshToken);
  createCookie("accessToken", accessToken, token.exp * 1000);
  createCookie("refreshToken", refreshToken, token.exp * 1000);
  return true;
};

const getUserData = (accessToken) => {
  const accessTokenDecode = decodeToken(accessToken);
  return {
    userEmailId: accessTokenDecode.email_id,
    userFName: accessTokenDecode.firstName || null,
    userLName: accessTokenDecode.lastName || null,
  };
};

const updateAccessTokenError = () => ({
  status: "400",
  message: "No token present",
});

const toastrMessage = (type, message) => {
  return <div><Img src={TOASTR_IMAGE[type]} alt="toastr img" className="toastr-img"></Img>{message}</div>

};


export {
  getCorrelationId,
  readCookie,
  eraseCookie,
  createCookie,
  formatLoginQuery,
  setUserToken,
  decodeToken,
  getUserData,
  authHeader,
  updateAccessTokenError,
  toastrMessage,
};
