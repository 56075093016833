import { React, Component } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router";
import { Utils } from "arv-reactcomponents";


import { eyeOpenIcon, eyeClosedIcon, GLOBAL_DASHBOARD_RESET_PWD } from "../../constants";
import { Button, Img, Modal, Input, Toastr } from "arv-reactcomponents";

const {
    resetPasswordTitle,
    resetPasswordSubtitle,
    resetPasswordBtn,
    passwordInfoCircle,
    greenSuccess,
    redError,
    passwordMismatchError,
    sucsessTitle,
    successSubtitle,
    successCheckImg,
    activationTitle,
    activationSubtitle,
} = GLOBAL_DASHBOARD_RESET_PWD;

@withRouter
@inject("LoginStore")
@observer
class ModifyPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: "",
            confirmPassword: "",
            newPasswordError: "",
            confirmPasswordError: "",
            newPasswordVisible: false,
            passwordVisible: false,
            passwordValid: false,
            passwordMatch: false,
            showConfig: false,
            passwordReset: false,
            neweyeIconImage: eyeClosedIcon,
            eyeIconImage: eyeClosedIcon,
            userActionError: "",
            showComponent: false,
        };
        this.passwordToggle = this.passwordToggle.bind(this);
        this.newpasswordToggle = this.newpasswordToggle.bind(this);
    }

    componentWillMount() {
        const { currentView, linkStatus, getPasswordConfig } = this.props.LoginStore;
        const token = new URLSearchParams(window.location.search).get('sessionId');
        if (token) {
            linkStatus(token).
                then(response => {
                    this.setState({ showComponent: true });
                    if (currentView === "setPassword" && response.data.status != "INVITED" ||
                        currentView === "resetPassword" && response.data.status != "ACTIVE"
                    ) {
                        Utils.localStorage.setItem(
                            "loginErrorMessage",
                            "User status is not supported."
                        );
                        this.props.history.push("/");
                    }
                    getPasswordConfig(response.data.tenantId)
                        .then(response => { })
                        .catch(error => {
                            console.error(error);
                        });
                })
                .catch(error => {
                    Utils.localStorage.setItem(
                        "loginErrorMessage",
                        error && error.message
                    );
                    this.props.history.push("/");
                });
        }
    }


    validateNewPassword = (value) => {
        const {
            confirmPassword,
        } = this.state;

        const { regex, errorMessage } = this.props.LoginStore.passwordConfig;

        const regexExpression = new RegExp(regex, 'g');
        let isValid = regexExpression.test(value);

        let error = isValid ? "" : errorMessage;
        let passwordMatch = value === confirmPassword;

        this.setState({
            newPasswordError: error,
            passwordValid: isValid,
        });
        if (confirmPassword.length > 1) {
            this.setState({
                passwordMatch,
                confirmPasswordError: !passwordMatch && passwordMismatchError
            });
        }
    };

    updateNewPassword = (event) => {
        const { value: newPassword } = event.target;
        this.setState({ newPassword });
        this.validateNewPassword(newPassword);
    };

    validateConfirmPassword = (value) => {
        const {
            newPassword,
        } = this.state;

        let errorMessage = newPassword === value ? "" : passwordMismatchError;
        this.setState({
            confirmPasswordError: errorMessage,
            passwordMatch: newPassword === value
        });
    };

    updateConfirmPassword = (event) => {
        const { value: confirmPassword } = event.target;
        this.setState({ confirmPassword, showConfig: false });
        this.validateConfirmPassword(confirmPassword);
    };

    infoClick = () => {
        this.setState({ showConfig: !this.state.showConfig });
    };

    crossClick = () => {
        this.props.history.push("/");
        this.props.LoginStore.changeView("login");
    }


    get passwordEyeClass() {
        return this.state.passwordVisible ? eyeOpenIcon : eyeClosedIcon;
    }

    passwordToggle() {
        const value = !this.state.passwordVisible;
        this.setState({
            passwordVisible: value,
            eyeIconImage: value ? eyeOpenIcon : eyeClosedIcon
        });
    }

    newpasswordToggle() {
        const value = !this.state.newPasswordVisible;
        this.setState({
            newPasswordVisible: value,
            neweyeIconImage: value ? eyeOpenIcon : eyeClosedIcon
        });
    }

    get confirmPasswordInputType() {
        return !this.state.passwordVisible ? "password" : "text";
    }
    get newPasswordInputType() {
        return !this.state.newPasswordVisible ? "password" : "text";
    }
    get InfoClassName() {
        return this.state.showConfig ? "" : "hide-overlay";
    }

    getImage = (condition) => {
        let password = this.state.newPassword;
        const regexExpression = new RegExp(condition.regex, 'g');
        let isValid = regexExpression.test(password);
        let src = isValid ? greenSuccess : redError;
        let className = isValid ? "success" : "error"
        return (<div className="password-overlay__checks">
            <Img src={src} alt="Icon" />
            <div className="password-overlay__text">
                {condition.text}
            </div></div>);
    };

    get passwordOverlay() {
        const { criteria } = this.props.LoginStore.passwordConfig;
        return (<div className={`password-overlay__container ${this.InfoClassName}`}>
            <div className="password-overlay__arrow"></div>
            <div className="password-overlay__title">Password must contain the following:</div>
            {criteria.map(condition => (this.getImage(condition)))}
        </div>);
    }

    redirectCountDown = value => {
        let count = value;
        let { emailId } = this.props.LoginStore;
        const timer = setInterval(() => {
            count = count - 1;
            if (count === 0) {
                clearInterval(timer);
                Utils.localStorage.setItem("emailId", emailId);
                this.props.history.push("/");
            }
        }, 1000);
    };

    passwordSubmitClick = () => {
        const { newPassword, confirmPassword, passwordValid, passwordMatch } = this.state;
        const { passwordSubmit, tenantId } = this.props.LoginStore;
        const { showLoaderView } = this.props;
        const token = new URLSearchParams(window.location.search).get('sessionId');

        this.validateConfirmPassword(confirmPassword);
        this.validateNewPassword(newPassword);


        if (newPassword && passwordValid && passwordMatch) {
            showLoaderView(true);
            passwordSubmit(newPassword, tenantId, token)
                .then((response) => {
                    showLoaderView(false);
                    this.setState({ passwordReset: true, redirectCounter: 3 });
                    this.redirectCountDown(5);

                })
                .catch((error) => {
                    this.setState({ userActionError: error && error.message });
                    showLoaderView(false);
                });
        }
    }

    get title() {
        let { currentView } = this.props.LoginStore;
        return currentView === "resetPassword" ? resetPasswordTitle : activationTitle;
    }
    get subTitle() {
        let { currentView, emailId } = this.props.LoginStore;
        return currentView === "resetPassword" ? resetPasswordSubtitle + emailId : activationSubtitle + emailId;
    }

    render() {
        const {
            newPassword,
            confirmPassword,
            newPasswordError,
            confirmPasswordError,
            passwordReset,
            neweyeIconImage,
            eyeIconImage,
            userActionError,
            showComponent,
        } = this.state;
        if (!showComponent) {
            return <div></div>;
        }
        return (
            <Modal
                isOpen={true}
                onClose={this.crossClick}
                className="login-overlay"
            >
                {passwordReset ? (<div>
                    <div className="gd-login-title">
                        {sucsessTitle}
                    </div>
                    <div className="gd-login-subtitle">
                        {successSubtitle}
                    </div>
                    <Img className="success-img" src={successCheckImg} alt="successImage" />
                </div>) : (<div>
                    <div className="gd-login-title">{this.title}</div>
                    <div className="gd-login-subtitle">{this.subTitle}</div>
                    <div className="reset-password-div">
                        <Input
                            type={this.newPasswordInputType}
                            className="input-field"
                            name="new_password"
                            placeholder="New Password"
                            value={newPassword}
                            autoComplete="off"
                            onChange={this.updateNewPassword}
                        />
                        <div>
                            <Img src={neweyeIconImage} className="input-field-icon" alt="eye Icon" onClick={this.newpasswordToggle}
                                onKeyPress={this.newpasswordToggle} />

                            <Img src={passwordInfoCircle} className="info-field-icon" alt="info Icon" onClick={this.infoClick} />
                            {this.passwordOverlay}
                        </div>
                        <div className="input-field-error">{newPasswordError}</div>
                    </div>
                    <div className="password-input-div">
                        <Input
                            type={this.confirmPasswordInputType}
                            className="input-field"
                            name="confirm_password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            autoComplete="off"
                            onChange={this.updateConfirmPassword}
                            onFocus={this.updateConfirmPassword}
                        />
                        <Img src={eyeIconImage} alt="eye Icon" className="input-field-icon"
                            onClick={this.passwordToggle}
                            onKeyPress={this.passwordToggle} />
                        <div className="input-field-error">{confirmPasswordError}</div>
                    </div>
                    <Button
                        className="overlay-button"
                        onClick={this.passwordSubmitClick}
                    >
                        {resetPasswordBtn}
                    </Button>
                    <div className="submit-error">{userActionError}</div>
                </div>)}
            </Modal >

        );
    }
}

export default ModifyPassword;
