import { React, Component } from "react";
import { Button, Img, Input, Utils, Toastr } from "arv-reactcomponents";
import { observer, inject } from "mobx-react";
import loadashGet from "lodash.get";
import { eyeOpenIcon, eyeClosedIcon, GLOBAL_DASHBOARD_LOGIN } from "../../constants";
import { toastrMessage } from "../../utils";

const {
  loginTitle,
  loginCTAText,
  emailRegEx,
  userEmailErrorText,
  userPasswordErrorText,
  loginErrorToastr,
  defaultLoginError,
  statusCode,
  forgotPasswordBtnText,
} = GLOBAL_DASHBOARD_LOGIN;

@inject("LoginStore")
@observer
class LoginSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userPassword: "",
      userEmailError: "",
      userPasswordError: "",
      passwordVisible: false,
      showLoader: false,
      eyeIconImage: eyeClosedIcon,
    };
  }

  componentDidMount() {
    const errorMessage = Utils.localStorage.getItem("loginErrorMessage");
    const emailId = Utils.localStorage.getItem("emailId");

    if (errorMessage) {
      Toastr.showToastr({
        className: "toastr-container toastr-error",
        message: toastrMessage("error", errorMessage),
        timeout: 3000,
      });
    }
    Utils.localStorage.removeItem("loginErrorMessage");
    if (emailId) {
      const { setEmailId } = this.props.LoginStore;
      setEmailId(emailId);
    }
    Utils.localStorage.removeItem("emailId");
  }
  updateUserEmail = (event) => {
    const { value } = event.target;
    const { setEmailId } = this.props.LoginStore;
    setEmailId(value);
    this.validateEmail(value);

  };
  updateUserPassword = (event) => {
    const { value } = event.target;
    this.setState({ userPassword: value });
    this.validatePassword(value);
  };
  validateEmail = value => {
    const userEmailError =
      (!emailRegEx.test(value) && userEmailErrorText) || "";
    this.setState({ userEmailError });
  };
  validatePassword = value => {
    const userPasswordError = (!value && userPasswordErrorText) || "";
    this.setState({ userPasswordError });
  };
  validatedCredentials = (event) => {
    const {
      userPassword = "",
      userEmailError,
      userPasswordError,
    } = this.state;
    const {
      loginAction,
      emailId
    } = this.props.LoginStore;
    const { showLoaderView, loginStateUpdate } = this.props;
    this.validateEmail(emailId);
    this.validatePassword(userPassword);
    if (emailId && userPassword && !userEmailError & !userPasswordError) {
      showLoaderView(true);
      loginAction(emailId, userPassword)
        .then((response) => {
          loginStateUpdate(true);
        })
        .catch((error) => {
          let userLoginError = "";
          if (statusCode.includes(error.status)) {
            userLoginError = loadashGet(error, "detail", defaultLoginError);
          } else {
            userLoginError = loadashGet(
              error,
              "message",
              loginErrorToastr
            );
          }
          this.setState({
            userEmailError: userLoginError,
          });
          showLoaderView(false);
        });
    } 
      return;
  };

  forgotPasswordClick = (event) => {
    this.props.LoginStore.changeView("forgotPassword");
  };

  get inputType() {
    return !this.state.passwordVisible ? "password" : "text";
  }

  passwordToggle = () => {
    const value = !this.state.passwordVisible;
    this.setState({ passwordVisible: value, eyeIconImage: value ? eyeOpenIcon : eyeClosedIcon });
  };

  render() {
    const {
      userPassword,
      userEmailError,
      userPasswordError,
      eyeIconImage,
    } = this.state;
    const { emailId } = this.props.LoginStore;
    return (
      <div className="gd-login-block">
        <div className="gd-login-section">
          <div className="gd-login-title">{loginTitle}</div>
          <div className="email-div">
            <Input
              type="email"
              className="input-field"
              name="user_email"
              placeholder="Email Id"
              value={emailId}
              autoComplete="off"
              onChange={this.updateUserEmail}
            />
            <div className="input-field-error">{userEmailError}</div>
          </div>
          <div className="password-input-div">
            <Input
              type={this.inputType}
              className="input-field"
              name="user_password"
              placeholder="Password"
              value={userPassword}
              autoComplete="off"
              onChange={this.updateUserPassword}
            />
            <Img src={eyeIconImage} alt="eye Icon" className="input-field-icon"
              onClick={this.passwordToggle}
              onKeyPress={this.passwordToggle} />
            <div className="input-field-error">{userPasswordError}</div>
          </div>
          <Button
            className="login-button"
            onClick={this.validatedCredentials}
          >
            {loginCTAText}
          </Button>
          <div className="forgot-pwd-container">
            <Button
              className="forgot-pwd-btn"
              onClick={this.forgotPasswordClick}
            >
              {forgotPasswordBtnText}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginSection;
