import { get } from '../api';

const getDetails = () => {
    return new Promise((resolve, reject) => {
        get('getDetails', {})
            .then(response => {
                resolve(response);
            })
            .catch(error => reject(error));
    });
}

export { getDetails };